<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import * as moment from 'moment'
import Swal from "sweetalert2";

DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: "100%",
  };
};

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Create Appointment",
    meta: [{ label: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, DatePicker, Multiselect },
  data() {
    return {
      title: "Form Create Appointment",
      items: [
        {
          text: "Home",
          href: "/home",
        },
        {
          text: "Appointment",
          href: "/appointment",
        },
        {
          text: "Create Appointment",
          active: true,
        },
      ],
      form: {
        user: null, 
        location:null,
        room:null,
        date: null,
        time: null,
        status:null,
        paymentStatus:null,
        paymentMethod: null,
        treatments: [{
          treatment:null,
          treatmentNotSelected: false
        }]
      },
      isError: false,
      errorMessage: null,
      optionsStatus: [
        "scheduled",
        "confirmed",
        "in progress",
        "canceled",
        "completed", 
      ],
      isStatusNotSelected:false, 
      optionsPayment: [
        "paid",
        "unpaid", 
      ],
      isPaymentStatusNotSelected:false, 
      optionsPaymentMethod: ['cash', 'debit', 'credit card', 'transfer', 'OVO', 'go pay', 'shopee pay', 'blibli in store', 'kredivo', 'voucher', 'package', 'yobo'],
      isPaymentMethodNotSelected: false,
      users: [],
      isLoadingUser: false,
      isUserNotSelected: false,
      locations: [],
      isLoadinglocations: false,
      islocationsNotSelected: false,
      rooms: [],
      isRoomNotSelected: false,
      treatments: [],
      timeSlot: [],
      isTimeNotSelected: false,
      isDateNotSelected: false
    };
  },
  mounted() {
    if (this.firstLocation) {
      this.$axios
        .get(`location`, {
          params: {
            sortBy: "name",
            sortType: 1,
            limit: 500,
          },
        })
        .then((response) => {
          console.log(response)
          let result = response.data.result.location;
          this.locations = result;
          this.form.location = this.locations.find(location => location.locationId == this.firstLocation)
        });
    }

    if (this.$route.query.date !== undefined) {
      const date = moment(this.$route.query.date).format('YYYY-MM-DD')
      const now = moment().format('YYYY-MM-DD')
      if (moment(date).isSameOrAfter(now)) {
        this.form.date = moment(date).toDate()
      }
    }

    if (this.$route.query.time !== undefined) {
      this.form.time = this.$route.query.time
      this.timeSlot.push(this.$route.query.time)
    }

    this.fetchTreatment()
  },
  computed: {
    firstLocation() {
      if (this.$store.getters.getStaffLocationId) {
        return this.$store.getters.getStaffLocationId
      }

      if (this.$route.query.location !== undefined) {
        return this.$route.query.location
      }

      return null
    }
  },
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit(e) {
      // validation
      this.isUserNotSelected = this.form.user === null ? true : false;
      this.islocationsNotSelected = this.form.location === null ? true : false;
      this.isRoomNotSelected = this.form.room === null ? true : false;
      this.isDateNotSelected = this.form.date === null ? true : false;
      this.isTimeNotSelected = this.form.time === null ? true : false;
      this.isStatusNotSelected = this.form.status === null ? true : false;
      this.isPaymentStatusNotSelected = this.form.paymentStatus === null ? true : false;
      this.isPaymentMethodNotSelected = (this.form.paymentStatus == "paid" && this.form.paymentMethod === null)? true : false;

      if (this.isUserNotSelected || this.islocationsNotSelected ||this.isRoomNotSelected || this.isDateNotSelected || this.isTimeNotSelected || this.isStatusNotSelected || this.isPaymentStatusNotSelected || this.isPaymentMethodNotSelected) {
        return false;
      }

      let valid = true
      this.form.treatments.forEach( t => {
        t.treatmentNotSelected = (t.treatment === null)? true : false;
      })

      this.form.treatments.forEach( t => {
        if(t.treatmentNotSelected) {
          valid = false
        }
      })

      console.log('status form ' + valid);
      if(!valid) return false

      // do your submit logic here
      let param = new FormData();
      param.append("user", this.form.user.userId);
      param.append("room", (this.form.room !== null)? this.form.room.roomId : null);
      param.append("location", this.form.location.locationId);
      param.append("date", moment(this.form.date).format('YYYY-MM-DD'));
      param.append("time", this.form.time);
      param.append("status", this.form.status);
      param.append("payment_status", this.form.paymentStatus); 
      param.append("payment_method", (this.form.paymentStatus == 'paid')? this.form.paymentMethod : null)
      this.form.treatments.forEach( t => {
        param.append('treatments', t.treatment.treatmentId)
      })
      this.$axios
        .post("/appointment", param)
        .then((response) => {
          console.log(response);
          Swal.fire("Success", "Appointment Created Successfully", "success");
          this.$router.push(`/appointment`);
        })
        .catch((e) => {
          if (e.response) {
            this.isError = true;
            this.errorMessage = e.response.data.message;
          }
        });
     },
    findUser(query) {
      console.log(query);
      this.isLoadingUser = true;
      this.$axios.get(`user`, {
          params: {
            search: query,
            sortBy: "name",
            sortType: 1,
          },
        }).then((response) => {
          console.log(response)
          let result = response.data.result.users;
          this.users = result.map( user => {
            return Object.assign(user, {
              fullname: user.firstname + ' ' + user.lastname
            })
          });
          this.isLoadingUser= false;
        });
    },
    fetchTreatment() {
      this.$axios
        .get(`treatment/list/active`, {
          params: {
            sortBy: "name",
            sortType: 1,
            limit: 500,
            location: this.firstLocation,
            availability: 'booking_system'
          },
        })
        .then((response) => {
          console.log(response)
          let result = response.data.result.treatment;
          this.treatments = result;
        });
    },
    addTreatment() {
      this.form.treatments.push({
        treatment: null,
        treatmentNotSelected: false,
        employee:null,
        employeeNotSelected: false,
        employees: []
      })
    },
    removeTreatment(index) {
      // if (this.form.treatments[index].treatment !== undefined && this.form.treatments[index].treatment !== null) {
      //   this.resetForm()
      // }
      this.form.treatments.splice(index, 1)
    },
    findLocation() {
      let param = new FormData()
      this.form.treatments.forEach( item => {
        if (item.treatment !== null) {
          param.append('treatments', item.treatment.treatmentId)
        }
      })
      this.$axios
        .post(`location/list/by-treatment`, param)
        .then((response) => {
          let result = response.data.result.location;
          this.locations = result;
        });
    },
    findRoom(selected) {
      let locationId = null;
      if (selected === null) {
        locationId = this.form.location.locationId
      } else {
        locationId = selected.locationId
      }
      this.resetForm('room')
      let param = new FormData()
      this.form.treatments.forEach( item => {
        if (item.treatment !== null) {
          param.append('treatments', item.treatment.treatmentId)
        }
      })
      this.$axios
        .post(`room/list/by-location/${locationId}`, param)
        .then((response) => {
          console.log(response)
          let result = response.data.result.room;
          this.rooms = result
        });
    },
    findTimeSlot(chosen) {
      this.resetForm('time')
      const date = moment(chosen).format('YYYY-MM-DD')
      let param = new FormData()
      param.append('date', date)
      this.form.treatments.forEach( t => {
        param.append('treatments', t.treatment.treatmentId)
      })
      this.$axios.post(`location/time-slot/${this.form.location.locationId}`, param)
        .then( response => {
          this.timeSlot = []
          response.data.result.timeSlot.forEach( data => {
            if (!data.disabled) {
              this.timeSlot.push(data.time)
            }
          })
        })
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    resetForm(section = null) {
      switch(section) {
        case 'location':
          this.form.location = null
          this.locations = []
          break;
        case 'room': 
          this.form.room = null
          this.rooms = []
          break;
        case 'date':
          this.form.date = null
          break;
        case 'time':
          this.form.time = null
          this.timeSlot = []
          break;
        default:
          // reset location
          this.form.location = null
          this.locations = []
          // reset room 
          this.form.room = null
          this.rooms = []
          // reset date
          this.form.date = null
          // reset time
          this.form.time = null
          this.timeSlot = []
          break;
      }
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert v-model="isError" variant="danger" class="mt-3" dismissible>{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>User</label>
                <multiselect
                  :class="{ 'is-invalid': isUserNotSelected }"
                  v-model="form.user"
                  label="fullname"
                  track-by="userId"
                  placeholder="Type to search"
                  open-direction="bottom"
                  :options="users"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoadingUser"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="false"
                  @search-change="findUser"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.firstname +' '+ option.lastname  }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="isUserNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group row" v-for="(item,i) in form.treatments" :key="i">
                <div class="col-md-9">
                  <label>Treatment</label>
                  <multiselect 
                    :id="`treatment_${i}`"
                    v-model="item.treatment"
                    label="name"
                    track-by="treatmentId"
                    placeholder="Type to search"
                    open-direction="bottom"
                    :maxHeight="500"
                    :options="treatments"
                    :multiple="false"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :hide-selected="false"
                    :allowEmpty="false"
                    :class="{ 'is-invalid': item.treatmentNotSelected }"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      <strong>{{ option.name }}</strong>
                    </template>
                  </multiselect>
                  <div v-if="item.treatmentNotSelected" class="invalid-feedback">
                    <span>This value is required.</span>
                  </div>
                </div>
                
                <div class="col-md-3 mt-4 pt-1 text-right">
                  <b-button class="mr-2" @click="addTreatment()" v-show="i == form.treatments.length-1" variant="info">Add Treatment</b-button>
                  <b-button variant="danger" @click="removeTreatment(i)" v-show="i || ( !i && form.treatments.length > 1)">Remove</b-button>
                </div>
              </div>

              <div class="form-group">
                <label>Location</label>
                <multiselect
                  :class="{ 'is-invalid': islocationsNotSelected }"
                  v-model="form.location"
                  label="name"
                  track-by="locationId"
                  placeholder="Select One"
                  open-direction="bottom"
                  :maxHeight="500"
                  :options="locations"
                  :multiple="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allowEmpty="false"
                  :disabled="$route.query.location !== undefined"
                  @open="findLocation"
                  @select="findRoom"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="islocationsNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Room</label>
                <multiselect
                  :class="{ 'is-invalid': isRoomNotSelected }"
                  v-model="form.room"
                  label="name"
                  track-by="roomId"
                  placeholder="Select One"
                  open-direction="bottom"
                  :options="rooms"
                  :maxHeight="500"
                  :multiple="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allowEmpty="false"
                  @open="findRoom"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="isRoomNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Date</label>
                <br />
                <date-picker
                  v-model="form.date"
                  format="DD MMMM YYYY"
                  name="date"
                  :first-day-of-week="1"
                  lang="en"
                  placeholder="Select One"
                  :class="{ 'is-invalid': isDateNotSelected }"
                  @pick="findTimeSlot"
                  :disabled-date="notBeforeToday"
                  :disabled="$route.query.date !== undefined"
                ></date-picker>
                <div v-if="isDateNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div> 

              <div class="form-group">
                <label>Select Time</label>
                <multiselect 
                  :class="{ 'is-invalid': isTimeNotSelected }" 
                  v-model="form.time" 
                  deselect-label="Can't remove this value" 
                  placeholder="Select one" 
                  :options="timeSlot" 
                  :searchable="false" 
                  :allow-empty="false"
                  :disabled="$route.query.time !== undefined"
                >
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isTimeNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Payment Status</label> 
                <multiselect :class="{ 'is-invalid': isPaymentStatusNotSelected }" v-model="form.paymentStatus" deselect-label="Can't remove this value" placeholder="Select one" :options="optionsPayment" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isPaymentStatusNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group" v-if="form.paymentStatus == 'paid'">
                <label>Payment Method</label> 
                <multiselect :class="{ 'is-invalid': isPaymentMethodNotSelected }" v-model="form.paymentMethod" deselect-label="Can't remove this value" placeholder="Select one" :options="optionsPaymentMethod" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isPaymentMethodNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Booking Status</label> 
                <multiselect :class="{ 'is-invalid': isStatusNotSelected }" v-model="form.status" deselect-label="Can't remove this value" placeholder="Select one" :options="optionsStatus" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isStatusNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>